<template>
    <el-card class="info_card">
      <div slot="header">
        <el-row type="flex" justify="space-between"
        ><el-col :span="10"><span>文章管理</span></el-col>
        <el-col :span="10">
          <el-input
            placeholder="请输入文章内容"
            v-model="keyWord"
            class="input-with-select"
            @keyup.enter.native="getInfo"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="getInfo"
            ></el-button>
          </el-input>
        </el-col>
        <el-col :span="1.5">
          <el-button
            type="success"
            round
            icon="el-icon-circle-plus-outline"
            @click="Add()"
            :loading="isLoading"
            >添加</el-button
          ></el-col
        ></el-row
      >
      </div>
      <el-container style="height: 100%; border: 1px solid #eee">
      <el-aside width="150px">
        <ArticleTypeSelect ref="ArticleTypeSelect"  @getCurrentArticleType="getCurrentArticleType"/>
      </el-aside>
      <el-main>
        <ArticleSelect ref="ArticleSelect"></ArticleSelect>
        <ArticleAdd ref="ArticleAdd" @getInfo="getInfo" />
      </el-main>
      </el-container>
    </el-card>
  </template>
  
  <script>
  import ArticleTypeSelect from "./ArticleTypeSelect/index.vue";
  import ArticleSelect from "./ArticleSelect/index.vue"
  import ArticleAdd from "./ArticleAdd/index.vue"
  export default {
    components: { ArticleTypeSelect,ArticleSelect,ArticleAdd },
    data() {
      return {
        isLoading: false,
        currentArticleType:"",
        keyWord:"",
        isLoading: false,
      };
    },
    methods: {
      getInfo() {
        this.$refs.ArticleSelect.handleGetArticleInfo(this.currentArticleType,this.keyWord);
      },
      getCurrentArticleType(articleType){
        this.currentArticleType = articleType
        this.$refs.ArticleSelect.handleGetArticleInfo(this.currentArticleType,this.keyWord)
      },
      Add(){
        this.$refs.ArticleAdd.handleOpen(this.currentArticleType)
      }
    },
  };
  </script>
  
  <style scoped>
  .info_card ::v-deep .el-card__body{
    padding: 0px;
  }
  </style>