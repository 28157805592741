<template>
  <div>
    <el-dialog
      title="编辑文章"
      :visible.sync="dialogEditFormVisible"
      width="90rem"
      :close-on-click-modal="false"
    >
      <el-form :model="form" :rules="rules" ref="form">
        <el-row type="flex"
          ><el-col
            ><el-form-item
              label="文章标题"
              :label-width="formLabelWidth"
              prop="title"
            >
              <el-input
                v-model="form.title"
                placeholder="请输入文章标题"
              ></el-input> </el-form-item></el-col
          ><el-col
            ><el-form-item
              label="文章类型"
              :label-width="formLabelWidth"
              prop="source"
            >
              <el-select
                v-model="form.article_type_un_code"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in articleTypeList"
                  :key="item.un_code"
                  :label="item.type_name"
                  :value="item.un_code"
                >
                </el-option>
              </el-select> </el-form-item></el-col
        ></el-row>
        <el-row type="flex"
          ><el-col :span="6"
            ><el-form-item
              label="文章来源"
              :label-width="formLabelWidth"
              prop="source"
            >
              <el-radio size="small" v-model="form.source" label="原创"
                >原创</el-radio
              >
              <el-radio size="small" v-model="form.source" label="转载"
                >转载</el-radio
              >
            </el-form-item></el-col
          ><el-col :span="16" v-if="form.source == '转载'"
            ><el-form-item
              label="文章链接"
              :label-width="formLabelWidth"
              prop="article_link"
            >
              <el-input
                v-model="form.article_link"
                placeholder="请输入文章原文链接"
              ></el-input> </el-form-item></el-col
        ></el-row>
        <el-row type="flex"
          ><el-col :span="6"
            ><el-form-item
              label="发布时间"
              :label-width="formLabelWidth"
              prop="publish_time"
            >
              <el-date-picker
                v-model="form.publish_time"
                type="datetime"
                placeholder="选择发布时间"
              >
              </el-date-picker> </el-form-item></el-col
          ><el-col :span="6"
            ><el-form-item
              label="文章状态"
              :label-width="formLabelWidth"
              prop="publish_status"
            >
              <el-radio v-model="form.publish_status" size="small" :label="1"
                >已保存</el-radio
              >
              <el-radio v-model="form.publish_status" size="small" :label="2"
                >已发布</el-radio
              >
            </el-form-item></el-col
          ></el-row
        >
        <WangEditor ref="WangEditor" @refreshContent="refreshContent"
      /></el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleSaveCancel()" icon="el-icon-circle-close"
          >取 消</el-button
        >
        <el-button
          type="primary"
          :loading="isLoading"
          @click="handleSave()"
          icon="el-icon-circle-check"
          >保 存</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import WangEditor from "../../../components/WangEditor/index.vue";
export default {
  name: "ArticleEdit",
  components: { WangEditor },
  data() {
    return {
      dialogEditFormVisible: false,
      isLoading: false,
      formLabelWidth: "5rem",
      //文章类型列表
      articleTypeList: [],
      form: {
        un_code: "",
        title: "",
        source: "原创",
        article_link:"",
        article_type_un_code: "",
        content: "",
        publish_time: "",
        publish_status: 0,
      },
      //表单验证规则
      rules: {},
    };
  },
  methods: {
    handleOpen(articleTypeUnCode, form) {
      this.form.article_type_un_code = articleTypeUnCode;
      this.dialogEditFormVisible = true;
      //赋值
      this.form.un_code = form.un_code;
      this.form.title = form.title;
      this.form.source = form.source;
      this.form.article_link = form.article_link;
      this.form.article_type_un_code = articleTypeUnCode;
      this.form.content = form.content;
      this.form.publish_time = this.timestampToTime(form.publish_time);
      this.form.publish_status = form.publish_status;
      this.$store
        .dispatch("articleType/select")
        .then((res) => {
          var { data } = res;
          this.articleTypeList = data.info_list;
        })
        .catch((err) => {
          console.log(err);
        });
        this.$nextTick(function(){
            this.$refs.WangEditor.initHtml(this.form.content);
        })
    },
    handleSaveCancel() {
      this.dialogEditFormVisible = false;
    },
    //编辑更新内容回调函数
    refreshContent(content) {
      this.form.content = content;
    },
    handleSave() {
      this.isLoading = true;
      //将日期时间转化为时间戳
      this.form.publish_time = this.handleDateTimeToTimestamp(
        this.form.publish_time
      );
      //设置文章类型
      this.form.article_type_un_code = this.currentArticleType;
      this.$store
        .dispatch("article/edit", this.form)
        .then((res) => {
          this.$message.success(res.msg);
          this.handleSaveCancel();
          this.$emit("getInfo");
        })
        .catch((err) => {
          console.log(err);
        });
      this.isLoading = false;
    },
    //日期时间转时间戳
    handleDateTimeToTimestamp(dateTime) {
      var time = new Date(dateTime);
      return time.getTime();
    },
    timestampToTime(timestamp) {
      var date = new Date(parseInt(timestamp)); //时间戳为10位需*1000，时间戳为13位的话不需乘1000,需要将字符串转化为int
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D =
        (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
      var h =
        (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      var m =
        (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
        ":";
      var s =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return Y + M + D + h + m + s;
    },
  },
};
</script>

<style>
</style>