<template>
  <div class="aside">
    <el-row type="flex" class="article_type_manage_pannel_top">
      <el-col
        ><span class="article_type_manage_pannel_title"
          ><b>文章类型</b></span
        ></el-col
      >
      <el-col>
        <el-button
          round
          size="mini"
          icon="el-icon-circle-plus-outline"
          @click="add()"
          >添加</el-button
        >
      </el-col>
    </el-row>
    <el-row>
      <!-- <el-menu v-for="(subMenu, index) in infoList" :key="index">
        <el-menu-item @click="changeIndex(subMenu)" :title="subMenu.type_name">
          <span slot="title">{{ subMenu.type_name }}</span>
        </el-menu-item>
      </el-menu> -->
        <el-tabs
          tab-position="left"
          style="height: 100%;"
          type="card"
          closable
          v-model="activeType"
          @tab-remove="handleRemove"
          @tab-click="handleClick"
        >
          <el-tab-pane
            :key="item.un_code"
            v-for="item in infoList"
            :label="item.type_name"
            :name="item.un_code"
          >
          </el-tab-pane>
        </el-tabs>
    </el-row>
    <ArticleTypeAdd ref="ArticleTypeAdd" @getInfo="getInfo"></ArticleTypeAdd>
  </div>
</template>

<script>
import ArticleTypeAdd from "../ArticleTypeAdd/index.vue";
export default {
  name: "ArticleTypeSelect",
  components: {
    ArticleTypeAdd,
  },
  data() {
    return {
      //数据列表
      infoList: [],
      //当前选中的数据
      activeType:""
    };
  },
  mounted() {
    this.getInfo();
  },
  methods: {
    getInfo() {
      this.$store.dispatch("articleType/select").then((res) => {
        var { data } = res;
        this.infoList = data.info_list;
        //如果文章类型列表不为空，则初始化当前选中的文章类型
        if(this.infoList.length > 0){
          this.activeType = this.infoList[0].un_code
          this.handleClick({name:this.activeType})
        }
      });
    },
    add() {
      this.$refs.ArticleTypeAdd.handleOpen();
    },
    handleClick(data) {
      this.$emit("getCurrentArticleType", data.name);
    },
    handleRemove(data) {
      this.$confirm(
        "确定删除该文章类型?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          this.$store.dispatch("articleType/del", { un_code: data }).then((res) => {
            this.$message.success(res.msg);
            this.getInfo();
          }).catch((error)=>{
            this.$message.error("删除失败！")
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
};
</script>

<style scoped>
.aside ::v-deep .el-menu {
  background-color: #f5f5f5;
}
.aside ::v-deep .el-menu-item span {
  color: #303030;
}
.aside ::v-deep .el-menu-item.is-active {
  color: #f5f5f5;
}
.article_type_manage_pannel_title {
  font-size: 0.9rem;
  vertical-align: middle;
}
.article_type_manage_pannel_top {
  margin: 10px 0px;
}
</style>