<template>
  <el-dialog title="添加文章类型" :visible.sync="dialogVisible" width="30%">
    <el-form :model="form">
      <el-form-item label="类型名称" :label-width="formLabelWidth">
        <el-input v-model="form.typeName" autocomplete="off"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="handleAdd()">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: "ArticleTypeAdd",
  data() {
    return {
      dialogVisible: false,
      formLabelWidth: "120px",
      form: {
        typeName: "",
      },
    };
  },
  methods: {
    handleOpen() {
      this.dialogVisible = true;
    },
    handleClose() {
      this.form.typeName = "";
      this.dialogVisible = false;
      this.$emit("getInfo");
    },
    handleAdd() {
      this.$store
        .dispatch("articleType/add", {
          type_name: this.form.typeName,
        })
        .then((res) => {
          this.$message.success(res.msg);
          this.handleClose();
        })
        .catch((err) => {
          this.handleClose();
          console.log(err);
        });
    },
  },
};
</script>

<style>
</style>