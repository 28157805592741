<template>
  <el-dialog
    title="添加文章"
    :visible.sync="dialogAddFormVisible"
    width="90rem"
    :close-on-click-modal="false"
  >
    <el-form :model="form" :rules="rules" ref="form">
      <el-row type="flex"
        ><el-col
          ><el-form-item
            label="文章标题"
            :label-width="formLabelWidth"
            prop="title"
          >
            <el-input
              v-model="form.title"
              placeholder="请输入文章标题"
            ></el-input> </el-form-item></el-col
        ><el-col
          ><el-form-item
            label="文章类型"
            :label-width="formLabelWidth"
            prop="source"
          >
            <el-select v-model="currentArticleType" placeholder="请选择">
              <el-option
                v-for="item in articleTypeList"
                :key="item.un_code"
                :label="item.type_name"
                :value="item.un_code"
              >
              </el-option>
            </el-select> </el-form-item></el-col
      ></el-row>
      <el-row type="flex"
        ><el-col :span="6"
          ><el-form-item
            label="文章来源"
            :label-width="formLabelWidth"
            prop="source"
          >
            <el-radio size="small" v-model="form.source" label="原创">原创</el-radio>
            <el-radio size="small" v-model="form.source" label="转载">转载</el-radio>
          </el-form-item></el-col
        ><el-col :span="16" v-if="form.source == '转载'"
          ><el-form-item
            label="文章链接"
            :label-width="formLabelWidth"
            prop="article_link"
          >
            <el-input
              v-model="form.article_link"
              placeholder="请输入文章原文链接"
            ></el-input> </el-form-item></el-col
      ></el-row>
      <el-row type="flex"
        ><el-col :span="6"
          ><el-form-item
            label="发布时间"
            :label-width="formLabelWidth"
            prop="publish_time"
          >
            <el-date-picker
              v-model="form.publish_time"
              type="datetime"
              placeholder="选择发布时间"
            >
            </el-date-picker> </el-form-item></el-col
      ></el-row>
      <WangEditor ref="WangEditor" @refreshContent="refreshContent" />
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="handleAddCancel()" icon="el-icon-circle-close"
        >取 消</el-button
      >
      <el-button
        type="primary"
        :loading="isLoading"
        @click="handleAdd()"
        icon="el-icon-circle-check"
        >创 建</el-button
      >
    </div>
  </el-dialog>
</template>
  
  <script>
import WangEditor from "../../../components/WangEditor/index.vue";
export default {
  name: "ArticleAdd",
  components: { WangEditor },
  data() {
    return {
      dialogAddFormVisible: false,
      isLoading: false,
      formLabelWidth: "5rem",
      //文章类型
      currentArticleType: "",
      //文章类型列表
      articleTypeList: [],
      form: {
        title: "",
        source: "原创",
        article_type_un_code:"",
        content: "",
        publish_time: "",
      },
      //表单验证规则
      rules: {},
    };
  },
  methods: {
    handleOpen(articleTypeUnCode) {
      this.$store
        .dispatch("articleType/select")
        .then((res) => {
          var { data } = res;
          this.articleTypeList = data.info_list;
          this.currentArticleType = articleTypeUnCode;
          this.dialogAddFormVisible = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //子组件加载完成回调函数
    editorMountedCallBack(){
        this.$refs.WangEditor.initHtml(this.form.content);
    },
    //编辑更新内容回调函数
    refreshContent(content) {
      this.form.content = content;
    },
    handleAdd() {
      this.isLoading = true;
      //将日期时间转化为时间戳
      this.form.publish_time = this.handleDateTimeToTimestamp(this.form.publish_time)
      //设置文章类型
      this.form.article_type_un_code = this.currentArticleType
      this.$store
        .dispatch("article/add", this.form)
        .then((res) => {
          this.$message.success(res.msg);
          this.handleAddCancel();
          this.$emit("getInfo");
        })
        .catch((err) => {
          console.log(err);
        });
      this.isLoading = false;
    },
    handleAddCancel() {
      this.form = {};
      this.dialogAddFormVisible = false;
    },
    //日期时间转时间戳
    handleDateTimeToTimestamp(dateTime){
        var time = new Date(dateTime)
        return time.getTime()
    }
  },
};
</script>
  
  <style>
</style>