<template>
  <div>
    <el-table :data="infoList" border style="width: 100%">
      <el-table-column prop="title" label="标题"> </el-table-column>
      <el-table-column prop="source" label="来源" width="80">
      </el-table-column>
      <el-table-column prop="article_link" label="文章链接" width="280">
      </el-table-column>
      <el-table-column prop="publish_status" label="状态" width="80"
        ><template slot-scope="scope">
          <el-tag
            :type="
              scope.row.publish_status == 1
                ? 'info'
                : scope.row.publish_status == 2
                ? 'success'
                : 'danger'
            "
            >{{
              scope.row.publish_status == 1
                ? "已保存"
                : scope.row.publish_status == 2
                ? "已发布"
                : "未知"
            }}</el-tag
          ></template
        >
      </el-table-column>
      <el-table-column prop="publish_time" width="160" label="发布时间"
        ><template slot-scope="scope">
          <span>{{ timestampToTime(scope.row.publish_time) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="create_time" width="160" label="创建时间"
        ><template slot-scope="scope">
          <span>{{ timestampToTime(scope.row.create_time) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="update_time" width="160" label="最新更新时间"
        ><template slot-scope="scope">
          <span>{{ timestampToTime(scope.row.update_time) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="150">
        <template slot-scope="scope">
          <el-button size="mini" @click="handleEdit(scope.$index, scope.row)"
            >编辑</el-button
          >
          <el-button
            size="mini"
            type="danger"
            @click="handleDelete(scope.$index, scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageNum"
      :page-sizes="[5, 10, 15, 20]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
    <ArticleEdit ref="ArticleEdit" @getInfo="getInfo" />
  </div>
</template>

<script>
import ArticleEdit from "../ArticleEdit/index.vue"
export default {
  name: "ArticleSelect",
  components:{ArticleEdit},
  data() {
    return {
      //分页数据--start
      pageSize: 10,
      pageNum: 1,
      total: 0,
      //分页数据--end
      infoList: [],
      form: {},
      //当前文章类型
      articleTypeUnCode:"",
      //模糊搜索关键词
      keyWord:"",
    };
  },
  methods: {
    getInfo() {
      this.$store
        .dispatch("article/select", {
          pagesize: this.pageSize,
          pagenum: this.pageNum,
          articleTypeUnCode: this.articleTypeUnCode,
          keyWord: this.keyWord,
        })
        .then((res) => {
          var { data } = res;
          this.infoList = data.info_list;
          this.total = data.total;
        });
    },
    handleGetArticleInfo(data,keyWord){
      this.articleTypeUnCode = data
      this.keyWord = keyWord
      this.getInfo()
    },
    //分页控件函数--start
    handleSizeChange(val) {
      this.pageSize = val;
      this.getInfo();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getInfo();
    },
    //分页控件函数--end
    //编辑列函数
    handleEdit(index, row) {
      this.$refs.ArticleEdit.handleOpen(this.articleTypeUnCode,row)
    },
    //删除列函数
    handleDelete(index, row) {
      this.$confirm(
        "此操作将删除文章：" + row.title + "  是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          this.$store.dispatch("article/del", { un_code: row.un_code }).then((res) => {
            this.$message.success(res.msg);
            this.getInfo();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    //时间戳转换为时间
    timestampToTime(timestamp) {
      var date = new Date(parseInt(timestamp)); //时间戳为10位需*1000，时间戳为13位的话不需乘1000,需要将字符串转化为int
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D =
        (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
      var h =
        (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      var m =
        (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
        ":";
      var s =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return Y + M + D + h + m + s;
    },
  },
};
</script>

<style>
</style>